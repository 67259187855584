import { defineStore } from 'pinia';
import { ref } from 'vue';

import { useIsLoggedIn } from '@core/auth';

import type { StartGameData } from 'web/src/modules/egs/types';
import { useEgsApi } from 'web/src/modules/egs/composables';

const useCasinoBetgamesStore = defineStore('casino-betgames', () => {
  const { isLoggedIn } = useIsLoggedIn();

  const {
    startGame: startGameApi,
  } = useEgsApi();

  const startGameData = ref<StartGameData>();

  async function startGame(): Promise<void> {
    startGameData.value = await startGameApi({
      id: 'betgames',
      isDemo: !isLoggedIn.value,
    });
  }

  function clearStartGameData(): void {
    startGameData.value = undefined;
  }

  return {
    startGameData,
    startGame,
    clearStartGameData,
  };
});

export default useCasinoBetgamesStore;
