import { logger } from '@leon-hub/logging';

import type { AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import { useCmsStore } from 'web/src/modules/cms/store';

export default class CasinoBetgamesPagePrefetch extends AbstractPrefetch {
  async prefetch(): AsyncAbstractPrefetchResult {
    try {
      await useCmsStore().fetchCmsContentTranslation({
        key: 'WEB2_BETGAMES_DESCRIPTION',
        silent: true,
      });
    } catch (error) {
      logger.warn('CasinoBetgamesPagePrefetch error', error);
    }
  }
}
