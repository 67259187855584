import type { Ref } from 'vue';
import { ref } from 'vue';

import { PostMessageBus } from '@leon-hub/postmessage-bus';

import type {
  CasinoBetgamesIframeWidgetEmits,
  CasinoBetgamesIframeWidgetProps,
} from 'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/types';
import type { IFrameWidgetName } from 'web/src/modules/framed-app/types';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import BetgamesWidgetPostMessageEvent, {
  BetgamesWidgetPostMessageBusInitiator,
} from 'web/src/modules/framed-app/components/BetgamesFramedWidget/utils/BetgamesWidgetPostMessageEvent';
import { betGamesIframeWidgetName } from 'web/src/modules/framed-app/constants';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface CasinoBetgamesIframeWidgetComposable {
  iframeName: IFrameWidgetName;
  iframeHeight: Ref<number>;
  gameUrl: Ref<string | undefined>;
  iframeMounted(): void;
  emitLoad(): void;
}

export default function useCasinoBetgamesIframeWidget(
  props: CasinoBetgamesIframeWidgetProps,
  emits: CasinoBetgamesIframeWidgetEmits,
): CasinoBetgamesIframeWidgetComposable {
  const iframeName = betGamesIframeWidgetName;
  const iframeHeight = ref(500);

  let postMessageBus: PostMessageBus | null = null;

  const gameUrl = useFramedWidgetUrl(iframeName);

  function iframeMounted(): void {
    const iframeContentWindow = requireContentWindowByIFrameName(iframeName);
    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: BetgamesWidgetPostMessageBusInitiator,
    });
    postMessageBus.emit(BetgamesWidgetPostMessageEvent.init, {
      gameHtml: props.gameHtml,
    });

    postMessageBus.on(BetgamesWidgetPostMessageEvent.stateChanged, () => {
      postMessageBus?.emit(BetgamesWidgetPostMessageEvent.init, {
        gameHtml: props.gameHtml,
      });
    });

    postMessageBus.on(BetgamesWidgetPostMessageEvent.changeHeight, ({ height }) => {
      iframeHeight.value = height;
    });

    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      postMessageBus.on(BetgamesWidgetPostMessageEvent.scrollTop, ({ scrollTop }) => {
        emitScrollTop(scrollTop);
      });
    }
  }

  function emitScrollTop(scrollTop: number): void {
    emits('scroll-top', scrollTop);
  }

  function emitLoad(): void {
    emits('load');
  }

  return {
    iframeName,
    iframeHeight,
    gameUrl,
    iframeMounted,
    emitLoad,
  };
}
