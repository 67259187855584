<script lang="ts" setup>
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import CasinoGameIframeError from 'web/src/modules/casino/components/CasinoGameIframeError/CasinoGameIframeError.vue';
import CasinoGameLoader from 'web/src/modules/casino/components/CasinoGameLoader/CasinoGameLoader.vue';
import CasinoBetgamesIframeWidget

  from 'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/CasinoBetgamesIframeWidget.vue';

import useCasinoBetgamesRoutePage from './composables/useCasinoBetgamesRoutePage';

const {
  wrapper,
  errorMessage,
  isFrameLoaded,
  isHtmlInjection,
  gameUrl,
  setFrameLoaded,
  onClose,
  onScrollTop,
} = useCasinoBetgamesRoutePage();
</script>

<template>
  <div v-auto-id="'CasinoBetgamesRoutePage'"
    ref="wrapper"
    :class="$style['betgames']"
  >
    <div :class="$style['betgames__wrapper']">
      <div
        v-if="errorMessage"
        :class="$style['betgames__iframe-error']"
      >
        <CasinoGameIframeError
          type="unavailable"
          :text="errorMessage"
          :button="{ text: $t('WEB2_CLOSE'), action: 'close' }"
          @click="onClose"
        />
      </div>
      <div
        v-if="!isFrameLoaded"
        :class="$style['betgames__loader']"
      >
        <CasinoGameLoader
          is-dark
        />
      </div>
      <VIframe
        v-if="!isHtmlInjection && gameUrl"
        name="betgames-name"
        :src="gameUrl"
        allow="autoplay; fullscreen; encrypted-media;"
        full-width
        hide-title
        :class="$style['betgames__iframe']"
        @load="setFrameLoaded"
      />
      <CasinoBetgamesIframeWidget
        v-else-if="isHtmlInjection && gameUrl"
        :game-html="gameUrl"
        :class="$style['betgames__iframe']"
        @load="setFrameLoaded"
        @scroll-top="onScrollTop"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.betgames {
  min-height: 100vh;
  overflow: hidden;
  border-radius: $betgamesRadius;
  box-shadow: $boxShadow;

  &__iframe {
    width: 100%;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__wrapper {
    position: relative;
    min-height: calc(100vh - #{$headerDesktopHeight});
  }

  &__iframe-error {
    @include z-index(base);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 126px;
    background: $betgamesErrorBackground;
  }
}
</style>
